import React from 'react'
import styled from 'styled-components'

// Elements
import TitlePrimary from 'components/elements/Titles/TitlePrimary'

// Components
import Layout from 'components/layout/Layout'

const Title = styled(TitlePrimary)`
  @media (min-width: 992px) {
    &:is(h1) {
      font-size: 60px;
    }
  }
`

const Wrapper = styled.section`
  background: rgb(129, 154, 91);
  background: radial-gradient(
    circle,
    rgba(129, 154, 91, 1) 0%,
    rgba(90, 111, 57, 1) 100%
  );

  color: ${({ theme }) => theme.color.light};

  @media (min-width: 992px) {
    height: calc(100vh - 80px - 408px);
  }

  @media (max-width: 991px) {
    height: 500px;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Wrapper>
      <div className="d-flex justify-content-center py-5 text-center align-items-center h-100">
        <div>
          <Title element="h1">404</Title>
          <p>Pagina niet gevonden.</p>
        </div>
      </div>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
